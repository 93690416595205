import { minHeight } from '@mui/system';
import React, { useState } from 'react';
import { CssBaseline, Box } from '@mui/material';
import Sidebar from '../components/Sidebar/sidebar';
import Navbar from '../components/Navbar/navbar';
import Footer from '../components/Footer/footer';
import DashboardContent from '../components/DashboardContent/dashboardContent';
import { useNavigate } from 'react-router-dom';
import "./dashboard.css";

const Dashboard = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flex: 1 }}>
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Navbar />
          <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
            <DashboardContent />
          </Box>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
