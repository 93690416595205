import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Avatar } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import "../../pages/dashboard.css";

const Navbar = () => {
  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <Typography variant="h6" noWrap component="div">
          SBOT LOGO
        </Typography>
        <IconButton color="inherit" sx={{ marginLeft: 'auto' }}>
          <NotificationsIcon />
        </IconButton>
        <Avatar sx={{ ml: 2 }} alt="User Avatar" src="/path/to/dummy-avatar.jpg" />
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
