import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import HistoryIcon from '@mui/icons-material/History';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ApprovalIcon from '@mui/icons-material/Approval';

import "../../pages/dashboard.css"; // Import your custom CSS if necessary
import { Link } from 'react-router-dom';

const Sidebar = () => {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 240,
        flexShrink: 0,
        backgroundColor: '#0D1117',
        '& .MuiDrawer-paper': {
          width: 240,
          boxSizing: 'border-box',
          backgroundColor: '#0D1117',
          color: 'white',
        },
      }}
    >
      <List>
        <ListItem button sx={{ mt: 2, '&:hover': { backgroundColor: '#537791' } }}>
          <ListItemIcon sx={{ color: 'white' }}>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" sx={{ color: 'white' }} />
        </ListItem>
        <ListItem button sx={{ mt: 2, '&:hover': { backgroundColor: '#537791' } }}>
          <ListItemIcon sx={{ color: 'white' }}>
            <SwapHorizIcon />
          </ListItemIcon>
          <ListItemText primary="Convert USDT" sx={{ color: 'white' }} />
        </ListItem>
        <ListItem button sx={{ mt: 2, '&:hover': { backgroundColor: '#537791' } }}>
          <ListItemIcon sx={{ color: 'white' }}>
            <HistoryIcon />
          </ListItemIcon>
          <ListItemText primary="Transaction History" sx={{ color: 'white' }} />
        </ListItem>
        <Link to="/admin/pages/approval">
          <ListItem button sx={{ mt: 2, '&:hover': { backgroundColor: '#537791' } }}>
            <ListItemIcon sx={{ color: 'white' }}>
              <ApprovalIcon />
            </ListItemIcon>
            <ListItemText primary="Approval" sx={{ color: 'white' }} />
          </ListItem>
        </Link>
        <ListItem button sx={{ mt: 2, '&:hover': { backgroundColor: '#537791' } }}>
          <ListItemIcon sx={{ color: 'white' }}>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Profile & Settings" sx={{ color: 'white' }} />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;
