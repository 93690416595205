import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ p: 2, mt: 'auto', backgroundColor: '#0D1117', color: 'white' }}>
      <Divider sx={{ mb: 1 }} />
      <Typography variant="body2" align="center">
        &copy; 2024 USDT Conversion. All Rights Reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
