import React, { useState, useEffect } from 'react';
import { 
  Card, 
  CardMedia, 
  CardContent, 
  Typography, 
  LinearProgress, 
  Box, 
  Grid, 
  Chip, 
  Button,
  Container,
  Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import './index.css';
import new1 from '../../src/assets/images/bot-7.jpeg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation'
import { useNavigate } from 'react-router-dom';

const PoolCard = styled(Card)(({ theme }) => ({
  maxWidth: 350,
  margin: 'auto',
  borderRadius: 16,
  boxShadow: '0 8px 15px rgba(0,0,0,0.1)',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: '0 12px 20px rgba(0,0,0,0.15)',
  },
  background: 'linear-gradient(145deg, #f0f4f8 0%, #ffffff 100%)',
}));

const PoolImage = styled(CardMedia)({
  height: 220,
  borderTopLeftRadius: 16,
  borderTopRightRadius: 16,
  objectFit: 'cover',
});

const JoinButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
  padding: theme.spacing(1.5),
  borderRadius: 12,
  textTransform: 'none',
  fontWeight: 600,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    transform: 'scale(1.02)',
  },
}));

const Pool = () => {
  const [timeRemaining, setTimeRemaining] = useState([]);
  const [progress, setProgress] = useState([]);

  const poolData = [
    {
      id: 1,
      name: 'Summer Challenge',
      image: new1,
      startDate: new Date('2024-07-01'),
      endDate: new Date('2024-08-31'),
      progressPercentage: 65,
    },
    {
      id: 2,
      name: 'Winter Wonderland',
      image: new1,
      startDate: new Date('2024-12-01'),
      endDate: new Date('2025-01-15'),
      progressPercentage: 80,
    },
    {
      id: 3,
      name: 'Spring Renewal',
      image: new1,
      startDate: new Date('2024-03-01'),
      endDate: new Date('2024-04-30'),
      progressPercentage: 50,
    },
    {
      id: 4,
      name: 'Spring Renewal',
      image: new1,
      startDate: new Date('2024-05-01'),
      endDate: new Date('2024-08-30'),
      progressPercentage: 50,
    },
    {
      id: 5,
      name: 'Spring Renewal',
      image: new1,
      startDate: new Date('2024-05-01'),
      endDate: new Date('2024-08-30'),
      progressPercentage: 50,
    },
    {
      id: 6,
      name: 'Spring Renewal',
      image: new1,
      startDate: new Date('2024-05-01'),
      endDate: new Date('2024-08-30'),
      progressPercentage: 50,
    },
  ];

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const now = new Date();
      const remainingTimes = poolData.map((pool) => {
        const difference = pool.endDate.getTime() - now.getTime();

        if (difference > 0) {
          const days = Math.floor(difference / (1000 * 60 * 60 * 24));
          const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((difference % (1000 * 60)) / 1000);
          return { days, hours, minutes, seconds };
        }
        return { days: 0, hours: 0, minutes: 0, seconds: 0 };
      });
      setTimeRemaining(remainingTimes);
    };

    const timer = setInterval(calculateTimeRemaining, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const progressArray = poolData.map((pool) => pool.progressPercentage);
    setProgress(progressArray);
  }, []);

  const handleJoinPool = (poolName) => {
    alert(`You have joined the ${poolName}!`);
  };

  const navigate = useNavigate();

  return (
    <div className="bg-img">
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      spaceBetween={20}
      slidesPerView={1}
      navigation
      // pagination={{ clickable: true }}
      autoplay={{ delay: 5000 }}
      breakpoints={{
        640: { slidesPerView: 1 },
        768: { slidesPerView: 2 },
        1024: { slidesPerView: 3 },
      }}
    >
      {poolData.map((pool, index) => (
        <SwiperSlide key={pool.id}>
          <PoolCard>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 700 }}>
                  {pool.name}
                </Typography>
                <Tooltip title="Time Remaining">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AccessTimeIcon color="primary" sx={{ mr: 1 }} />
                    <Typography variant="body2" color="text.secondary">
                      {timeRemaining[index]
                        ? `${timeRemaining[index].days}d : ${timeRemaining[index].hours}h`
                        : 'Calculating...'}
                    </Typography>
                  </Box>
                </Tooltip>
              </Box>
              <PoolImage component="img" alt={pool.name} image={pool.image} />
              <Typography variant="body2" color="text.secondary" sx={{ mt: 2, textAlign: 'center', fontStyle: 'italic' }}>
                {pool.description}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Chip
                  icon={<CalendarTodayIcon />}
                  label={`Start: ${formatDate(pool.startDate)}`}
                  color="primary"
                  variant="outlined"
                  size="small"
                />
                <Chip
                  icon={<CalendarTodayIcon />}
                  label={`End: ${formatDate(pool.endDate)}`}
                  color="secondary"
                  variant="outlined"
                  size="small"
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    Max Limit 10,000
                  </Typography>
                  <Tooltip title="Progress">
                    <EmojiEventsIcon color="primary" fontSize="small" />
                  </Tooltip>
                </Box>
                <LinearProgress
                  variant="determinate"
                  value={progress[index] || 0}
                  sx={{
                    height: 10,
                    borderRadius: 5,
                    backgroundColor: '#e0e0e0',
                    '& .MuiLinearProgress-bar': {
                      borderRadius: 5,
                      background: 'linear-gradient(90deg, #4b6cb7 0%, #182848 100%)',
                    },
                  }}
                />
                <Typography variant="body2" color="text.secondary" align="right">
                  {`${progress[index] || 0}%`}
                </Typography>
              </Box>
              <JoinButton 
                variant="contained" 
                // onClick={() => handleJoinPool(pool.name)}
                startIcon={<EmojiEventsIcon />}
              >
                Join Pool
              </JoinButton>
            </CardContent>
          </PoolCard>
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
  );
};

export default Pool;