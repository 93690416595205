import gr1 from "../assets/images/gr-1.png";
import gr2 from "../assets/images/grr2.png";
import React, { useState, useEffect } from "react";
import fiveImg from "../assets/images/five-img.png";
import coinNew from "../assets/images/coin-new.png";
import coinSell from "../assets/images/coin-sell.png";
import shapeRight from "../assets/images/shape-right.png";
import "../App.css";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/app-logo.png";
import { useAccount, useConnect, useDisconnect, useBalance, useWriteContract, useWaitForTransactionReceipt, } from 'wagmi';
import { parseUnits, formatUnits } from 'ethers';
import Loader from "../Loader/loader";
import CustomConnectButton from "../customConnectButton/customConnectButton";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { sabotContractaddress, sabotContractaddressAbi, usdtApproveAddress, usdtApproveAddressAbi, usdtContractAbi, usdtContractAddress } from "../common/mainnet-abi";
import { toast } from "react-toastify";
import Web3 from 'web3';
import { fetchTransactions, transData } from "../redux/auth/auth";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";



function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [txn, setTxn] = useState([]);
  const { address, isConnected } = useAccount();

  const { disconnect } = useDisconnect();
  // const { data: balanceData } = useBalance({ address });
  const [usdTokens, setUsdTokens] = useState("");
  const [loadingButton, setLoadingButton] = useState(null);
  const walletAddress = address;
  localStorage.setItem("walletAddress", walletAddress);
  // State for buy and sell inputs
  const [buyInputEth, setBuyInputEth] = useState("");
  const [sellInputEthers, setSellInputEthers] = useState("");
  const [filmTokens, setFilmTokens] = useState("");
  const [transactionConfirmed, setTransactionConfirmed] = useState(false);
  const [balance, setBalance] = useState(null);
  const [usdt, setUsdt] = useState(null);

  // State for transaction and UI control
  const [isDisabled, setIsDisabled] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [timeLeft, setTimeLeft] = useState(90061);
  const allData = useSelector((state) => state.auth.allData);

  // console.log('allData', allData)
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const calculateTime = (seconds) => {
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return { days, hours, minutes, secs };
  };

  const { days, hours, minutes, secs } = calculateTime(timeLeft);

  // const [modalData, setModalData] = useState({
  //   title: "Wallet Not Connected",
  //   body: "Please Connect your Wallet first to access Exchange page.",
  //   for: "",
  // });
  const [currentPage, setCurrentPage] = useState(1);
  // const [transactions, setTransactions] = useState([]);
  // const polygonscanApiKey = "BNPZMFG49VXXRI29JXV1HIPM1K9YPRAQ9D";
  const [stateBuy, setStateBuy] = useState("");
  const infuraProjectId =
    "06DLwXs4GGm2E7epZyJwPEivhkOSGmx/pSiX1O/OCTG8TyOTFSmN5g";
  const infuraUrl = `https://mainnet.infura.io/v3/${infuraProjectId}`;

  const adrs = localStorage.getItem("walletAddress")

  useEffect(() => {
    dispatch(fetchTransactions(dispatch))
    if (adrs == "undefined") {
      dispatch(transData())
    }
  }, [adrs])

  const {
    data: hash,
    isPending,
    error: writeContractError,
    writeContract
  } = useWriteContract();

  const {
    isLoading: isConfirming,
    isSuccess: isConfirmed
  } = useWaitForTransactionReceipt({ hash });


  const ITEMS_PER_PAGE = 5;
  const reversedTransactions = [...allData || []];
  const totalItems = reversedTransactions?.length;
  const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentTransactions = reversedTransactions.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );


  const handleBuyInputChange = (e) => {
    const value = e.target.value;
    setBuyInputEth(value);


    const conversionRate = 1;
    setFilmTokens((value * conversionRate).toString());
  };

  const handleTokenBuy = async () => {
    if (!isConnected) {
      toast.error("Please connect your wallet first");
      return;
    }

    setLoadingButton('buy');

    try {
      const amount = parseUnits(buyInputEth, 6);

      await writeContract({
        address: usdtApproveAddress,
        abi: usdtApproveAddressAbi,
        functionName: "approve",
        args: ['0x8C0a0C754077582F85680a56eC6a38CAc8aaE93E', amount],
      });

      await writeContract({
        address: usdtContractAddress,
        abi: usdtContractAbi,
        functionName: "buyTokens",
        args: [amount],
      });
      dispatch(fetchTransactions(dispatch))

    } catch (error) {
      setLoadingButton(null);
      toast.error(`Transaction failed. Please check your input and wallet connection`);

    }
  };

  // const currentTransactions = allData.slice(
  //   (currentPage - 1) * ITEMS_PER_PAGE,
  //   currentPage * ITEMS_PER_PAGE
  // );


  const handleSellInputEtheres = (e) => {
    const value = e.target.value;
    setSellInputEthers(value);

    const calculatedUsdTokens = value * 1;
    setUsdTokens(calculatedUsdTokens.toString());
  };

  // const handleSellToken = async () => {
  //   if (!isConnected) {
  //     toast.error("Please connect your wallet first");
  //     return;
  //   }

  //   if (!sellInputEthers || isNaN(Number(sellInputEthers)) || Number(sellInputEthers) <= 0) {
  //     toast.error("Please enter a valid quantity to sell");
  //     return;
  //   }

  //   setLoadingButton('sell');

  //   try {
  //     const amount = parseUnits(sellInputEthers, 18);


  //     await writeContract({
  //       address: sabotContractaddress,
  //       abi: sabotContractaddressAbi,
  //       functionName: "approve",
  //       args: [usdtContractAddress, amount],
  //     });



  //     await writeContract({
  //       address: usdtContractAddress,
  //       abi: usdtContractAbi,
  //       functionName: "sellTokens",
  //       args: [amount],
  //     });

  //     await dispatch(fetchTransactions())


  //   } catch (error) {

  //     setLoadingButton(null);
  //     toast.error(`Sell transaction failed: User rejected the request`);
  //   }
  // };


  const handleNextPage = (event) => {
    event.preventDefault();
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = (event) => {
    event.preventDefault();
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };


  const formatDateTime = (timeStamp) => {
    const timestamp = Number(timeStamp);
    const date = new Date(timestamp * (timestamp < 1e10 ? 1000 : 1));
    if (isNaN(date.getTime())) {
      return "Invalid date";
    }
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(date);
  };

  useEffect(() => {
    if (isConfirmed) {
      // Reset loading state
      setLoadingButton(null);

      // Show success toast
      toast.success("Transaction successful!");

      // Reset form inputs
      setBuyInputEth("");
      setFilmTokens("");
      setSellInputEthers("");
      setUsdTokens("");
    }

    if (writeContractError) {
      // Reset loading state
      setLoadingButton(null);

      // Show error toast with more specific message
      toast.error(`Transaction failed: User rejected the request.`);
    }
  }, [isConfirmed, writeContractError]);

  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
      const web3 = new Web3(window.ethereum);

      // Request wallet access
      window.ethereum.request({ method: 'eth_requestAccounts' })
        .then(async (accounts) => {
          const account = accounts[0];
          const tokenAddress = sabotContractaddress;
          const tokenABI = sabotContractaddressAbi;

          const contract = new web3.eth.Contract(tokenABI, tokenAddress);

          // Fetch the balance
          const balanceInWei = await contract.methods.balanceOf(account).call();
          const balanceInTokens = web3.utils.fromWei(balanceInWei, 'ether');

          setBalance(Number(balanceInTokens).toFixed(2));

          if (balanceInTokens === null || balanceInTokens === undefined) {
            window.location.reload();
          }
        })

        .catch((err) => {
          console.error('User denied wallet connection:', err);
        });
      setBalance("0")
    }
  }, [adrs]);

  useEffect(() => {
    const fetchUSDTBalance = async () => {
      if (typeof window.ethereum !== 'undefined') {
        try {
          const web3 = new Web3(window.ethereum);

          const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
          const account = accounts[0];

          const contract = new web3.eth.Contract(usdtApproveAddressAbi, usdtApproveAddress);

          const balanceInWei = await contract.methods.balanceOf(account).call();
          const balanceInUsdt = web3.utils.fromWei(balanceInWei, 'ether');

          const formattedBalance = Number(balanceInUsdt).toFixed(2);
          setUsdt(formattedBalance);

          if (formattedBalance === true || formattedBalance === undefined) {
            window.location.reload();
          }
        } catch (err) {
          setUsdt('0');
        }
      }
    };

    fetchUSDTBalance();
  }, [adrs]);



  return (
    <div className="bf-footer only-das-footer">
      {stateBuy != "" && transactionConfirmed ? <Loader /> : ""}
      {/* Header Connect Button */}
      <section className="header-custom">
        <nav className="navbar navbar-expand-lg bg-light">
          <div className="container-fluid">
            <div className="navbar-brand logo-container">
              <span className="logo-text">SBOT Balance: {balance ? balance : '0'}</span>
              <span className="logo-text">USDT Balance: {usdt ? usdt : '0'}</span>
            </div>
            <div className="right-bt">
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginRight: '10px',
                  backgroundColor: '#1E3A8A',
                  color: '#ffffff',
                  padding: '8px 16px',
                  borderRadius: '8px',
                  fontWeight: 'bold',
                }}
                onClick={() => {
                  console.log('Investment clicked');
                  navigate('/pool');
                }}
              >
                Investment
              </Button>

              <Button
                variant="outlined"
                color="secondary"
                style={{
                  marginRight: '10px',
                  borderColor: '#1E3A8A',
                  color: '#1E3A8A',
                  padding: '8px 16px',
                  borderRadius: '8px',
                  fontWeight: 'bold',
                }}
                onClick={() => console.log('Withdraw clicked')}
              >
                Withdrawal
              </Button>

              <ConnectButton />
            </div>

          </div>
        </nav>
      </section>

      <section className="banner-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="DECENTRALIZED-1">
                <p className="DECENTRALIZED-p-1" style={{ marginTop: "50px" }}>
                  {/* YOUR MASTER IN <br />
                  THE ART OF <br />
                  AUTOMATED TRADING */}
                  SENSEIBOT
                </p>
                <div className="countdown-container">
                  <h1>Token sale ends in:</h1>
                  <div className="timer">
                    <div className="time-section">
                      <span className="time">{days}</span>
                      <span className="label">Days</span>
                    </div>
                    <span className="separator">:</span>
                    <div className="time-section">
                      <span className="time">{hours.toString().padStart(2, "0")}</span>
                      <span className="label">Hours</span>
                    </div>
                    <span className="separator">:</span>
                    <div className="time-section">
                      <span className="time">{minutes.toString().padStart(2, "0")}</span>
                      <span className="label">Minutes</span>
                    </div>
                    <span className="separator">:</span>
                    <div className="time-section">
                      <span className="time">{secs.toString().padStart(2, "0")}</span>
                      <span className="label">Seconds</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <p
                style={{
                  color: "white",
                  marginBottom: "20px",
                  marginTop: "20px",
                  padding: "10px 20px",
                  textAlign: "center",
                }}
              >
                SBOT aims to move beyond speculation by offering a tool
                that generates real value through a participation model
                and an automated, efficient trading bot.<br /> The integration
                of mechanisms like buyback, burning, and liquidity
                provision ensures long-term sustainability.<br /> Furthermore,
                future projects like educational games powered by SBOT
                promise significant growth potential.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="complet-bit">
        {/* <div className="navbar-brand logo-container">
          <span className="logo-text">SBOT Balance: 98</span>
          <span className="logo-texts">USDT Balance: 98</span>
        </div> */}

        <img className="acc-img-2" src={fiveImg} alt="" />
        <div className="container">
          <div
            className="row flex-strch resp_modal"
            style={{ display: "flex", gap: "20px", marginLeft: "100px" }}
          >
            <div className="col-lg-6">
              <div
                className="strch-1"
                style={{
                  padding: "20px",
                  boxSizing: "border-box",
                  overflow: "hidden",
                }}
              >
                {/* <img className="green-img" src={coinNew} alt="" /> */}
                <div className="bor-bottom">
                  <p className="red-img-right">
                    BUY
                  </p>
                </div>
                <div className="bottom-shadow"></div>
                <div className="buy-flex">
                  <ul>
                    {/* <li style={{ marginBottom: "15px" }}>
                      <label htmlFor="">Coin</label>
                      <input
                        className="input-all"
                        type="text"
                        value=""
                        readOnly
                        style={{
                          width: "100%",
                          padding: "8px",
                          boxSizing: "border-box",
                        }}
                      />
                    </li> */}
                    {/* <li style={{ marginBottom: "15px" }}>
                      <label>Wallet Balance: {balanceData ? 
                        `${formatUnits(balanceData.value, balanceData.decimals)} ${balanceData.symbol}` : 
                        'Not connected'}</label>
                    </li> */}
                    <li style={{ marginBottom: "15px" }}>
                      <label htmlFor="">Quantity</label>
                      <input
                        className="input-all"
                        type="text"
                        value={buyInputEth}
                        onChange={handleBuyInputChange}
                        disabled={!isConnected}
                        style={{ width: "100%", padding: "8px", boxSizing: "border-box" }}
                      />
                    </li>
                    <li style={{ marginBottom: "15px" }}>
                      <label htmlFor="">SBOT Tokens</label>
                      <input
                        className="input-all"
                        type="text"
                        value={filmTokens}
                        readOnly
                        style={{ width: "100%", padding: "8px", boxSizing: "border-box" }}
                      />
                    </li>
                    <li>
                      <button
                        className="BUY-BT"
                        style={{ width: "100%" }}
                        onClick={handleTokenBuy}
                        disabled={!isConnected || loadingButton === 'buy'}                  >
                        {loadingButton == 'buy' ? 'Processing...' : 'BUY SBOT NOW'}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Sell section */}
            <div className="col-lg-6">
              <div
                className="strch-1"
                style={{
                  padding: "20px",
                  boxSizing: "border-box",
                  overflow: "hidden",
                }}
              >
                <div className="bor-bottom">
                  <p className="red-img-left">SELL</p>
                </div>
                <div className="bottom-shadow"></div>
                <div className="buy-flex">
                  <ul>
                    <li style={{ marginBottom: "15px" }}>
                      <label htmlFor="sellQuantity">Quantity</label>
                      <div className="right-buy input-container">
                        <input
                          id="sellQuantity"
                          className="input-all"
                          type="text"
                          value={sellInputEthers}
                          onChange={handleSellInputEtheres}
                          disabled={isVisible}
                          style={{
                            width: "100%",
                            padding: "8px",
                            boxSizing: "border-box",
                          }}
                        />
                      </div>
                    </li>
                    <li style={{ marginBottom: "15px" }}>
                      <label htmlFor="usdTokens">USD Tokens</label>
                      <div className="right-buy">
                        <input
                          id="usdTokens"
                          className="input-all"
                          type="text"
                          value={usdTokens}
                          readOnly
                          style={{
                            width: "100%",
                            padding: "8px",
                            boxSizing: "border-box",
                          }}
                        />
                      </div>
                    </li>
                    <li>
                      <button
                        className="BUY-BT sell-bt"
                        style={{ width: "100%" }}
                        // onClick={handleSellToken}
                        disabled={!isConnected || loadingButton === 'sell'}
                      >
                        {loadingButton === 'sell' ? 'Processing...' : 'SELL SBOT NOW'}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>


            {/*sell section end*/}
            <div className="row margin-uk"></div>
          </div>
          <div
            className="container"
            style={{
              maxWidth: "1140px",
              marginLeft: "auto",
              marginRight: "auto",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <div
              className="row justify-content-center"
              style={{ marginTop: "20px", marginLeft: "-15px", marginRight: "-15px" }}
            >
              <div
                className="col-lg-12 mx-auto"
                style={{ paddingLeft: "15px", paddingRight: "15px" }}
              >
                <div className="all-same-div">
                  <div className="top-h">
                    <p>TRANSACTIONS HISTORY</p>
                  </div>
                  <div className="table-btc">
                    <div style={{ overflowX: "auto" }}>
                      <table>
                        <thead>
                          <tr style={{ color: "white" }}>
                            <th>Serial No</th>
                            <th>Hash</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Value</th>
                            <th>Method</th>
                            <th>Timestamp</th>
                          </tr>
                        </thead>
                        <tbody style={{ color: "white", fontSize: "12px", textAlign: "center" }}>
                          {(currentTransactions?.length > 0) ? (
                            currentTransactions.map((tx, ind) => (
                              <tr key={ind}>
                                <td>{ind + 1}</td>
                                <td>{tx.hash?.slice(0, 20)}</td>
                                <td>{tx.from?.slice(0, 20)}</td>
                                <td>{tx?.to}</td>
                                <td>{tx?.value}</td>
                                <td>{tx.functionName?.split("(")[0]}</td>
                                <td>{formatDateTime(tx?.timeStamp)}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="6"
                                style={{ textAlign: "center", padding: "100px 0" }}
                              >
                                <div className="border-price-content">
                                  <p
                                    className="border-price-p"
                                    style={{ textAlign: "center", }}
                                  >
                                    NO DATA FOUND
                                  </p>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>

                      </table>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "right",
                        gap: "10px",
                      }}
                    >
                      <a
                        href="#"
                        onClick={handlePreviousPage}
                        style={{
                          marginRight: "10px",
                          color: "white",
                          textDecoration: "none",
                          cursor: "pointer",
                          visibility: currentPage === 1 ? "hidden" : "visible",
                        }}
                      >
                        Previous
                      </a>
                      <a
                        href="#"
                        onClick={handleNextPage}
                        style={{
                          color: "white",
                          textDecoration: "none",
                          cursor: "pointer",
                          visibility:
                            currentPage === Math.ceil(allData?.length / ITEMS_PER_PAGE)
                              ? "hidden"
                              : "visible",
                        }}
                      >
                        Next
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Border Price Section */}
      <section section className="border-price-sec justify-content-center" >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <div className="border-price-content">
                <p className="border-price-p" style={{ textAlign: "center", color: "#000" }}>
                  SenseiBot (SBOT)
                </p>
              </div>
              <p
                style={{
                  color: "#000",
                  marginBottom: "20px",
                  marginLeft: "80px",
                  marginRight: "80px",
                  textAlign: "center",
                }}
              >
                SenseiBot (SBOT) is a utility token designed to create
                value for the crypto community through an innovative
                ecosystem based on a mining like participation model.
                SBOT enables users to earn rewards by contributing to
                the ecosystem, driven by an advanced trading bot. In the
                long term, SBOT will evolve into a currency for exchange
                and payment within the ecosystem, promoting
                sustainability and real utility. The project is supported by
                "Ryushi Ryu," a team of expert traders and developers,
                focused on transforming interactions with the crypto
                world.
              </p>
            </div>
          </div>
        </div>
      </section >
    </div >
  );
}

export default Home;
