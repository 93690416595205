import React from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import SignUp from "./app/pages/SignUp";
import ForgotPassword from "./app/pages/ForgotPassword";
import Home from "./home/Home";
import Login from "./app/pages/Login";
import OtpVerify from "./app/pages/OtpVerify";
import Dashboard from "./app/admin/pages/Dashboard";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "./Loader/loader";
import { useSelector } from "react-redux";
import Pool from "./pool";
import Updatedhome from "./updatehome";

function App() {
  const { isLoading } = useSelector((state) => state.auth);
  return (
    <>
      <ToastContainer />
      {isLoading && <Loader />}
      <Router>
          <Routes>
            <Route path="/" element={<Updatedhome />} />
            <Route path="/home" element={<Home />} />
            <Route path="/pool" element={<Pool />} />
            <Route path="/login" element={<Login />} />
            {/* <Route path="/home" element={<Home />} /> */}
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/otp" element={<OtpVerify />} />
            <Route path="/admin/pages/Dashboard" element={<Dashboard />} />
          </Routes>
      </Router>
    </>
  );
}

export default App;
