import React from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  MenuItem,
} from "@mui/material";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";
import "../../pages/dashboard.css";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

const transactions = [
  {
    id: 1,
    date: "2024-11-01",
    amount: "$100",
    currency: "USDT",
    convertedTo: "BTC",
    profit: "21%",
  },
  {
    id: 2,
    date: "2024-11-02",
    amount: "$200",
    currency: "USDT",
    convertedTo: "ETH",
    profit: "17%",
  },
  {
    id: 3,
    date: "2024-11-03",
    amount: "$50",
    currency: "USDT",
    convertedTo: "BTC",
    profit: "11%",
  },
];

// Pie chart data
const profitLossData = {
  labels: ["Profit", "Loss", "Pending Transactions", "Fees"],
  datasets: [
    {
      data: [500, 200, 100, 50],
      backgroundColor: ["#4caf50", "#f44336", "#ff9800", "#2196f3"],
      hoverOffset: 4,
    },
  ],
};

const DashboardContent = () => {
  return (
    <Box sx={{ mt: 10, ml:2, mr: 2 }}>
      <Grid container spacing={3}>
        {/* Balance Overview */}
        <Grid item xs={12} sm={6} md={6}>
          <Paper sx={{ p: 2, height: "100%" }}>
            <Typography variant="h4">Balance Overview</Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              USDT Balance: $1,000
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              BTC Balance: 0.5 BTC
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              ETH Balance: 2 ETH
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              Total Assets: $3,500
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              Last Transaction: +$200 USDT
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              Pending Withdrawals: $500
            </Typography>
          </Paper>
        </Grid>

        {/* Conversion Panel */}
        <Grid item xs={12} sm={6} md={6}>
          <Paper sx={{ p: 2, height: "100%" }}>
            <Typography variant="h6">Convert USDT</Typography>
            <TextField
              label="Amount in USDT"
              fullWidth
              variant="outlined"
              sx={{ mt: 2 }}
            />
            <TextField
              label="Convert To"
              fullWidth
              variant="outlined"
              select
              sx={{ mt: 2 }}
            >
              <MenuItem value="BTC">Bitcoin (BTC)</MenuItem>
              <MenuItem value="ETH">Ethereum (ETH)</MenuItem>
            </TextField>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
            >
              Convert
            </Button>
          </Paper>
        </Grid>

        {/* Transaction History */}
        <Grid item xs={12} md={12} lg={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">Transaction History</Typography>
            <TableContainer component={Box} sx={{ mt: 2, maxHeight: 400 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Amount</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Currency</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Converted To</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Profit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.map((transaction) => (
                    <TableRow key={transaction.id}>
                      <TableCell>{transaction.date}</TableCell>
                      <TableCell>{transaction.amount}</TableCell>
                      <TableCell>{transaction.currency}</TableCell>
                      <TableCell>{transaction.convertedTo}</TableCell>
                      <TableCell>{transaction.profit}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        {/* Pie Chart for Profit and Loss */}
        {/* <Grid item xs={12} sm={5} md={5}>
          <Paper sx={{ p: 2, height: "100%" }}>
            <Typography variant="h6" align="center">
              Total Financial Overview
            </Typography>
            <Box
              sx={{
                height: 300,
                mt: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pie data={profitLossData} />
            </Box>
          </Paper>
        </Grid> */}

        <Grid item sx={{ mb: 2 }} xs={12} sm={5} md={5}>
        <Paper sx={{ p: 2, height: "100%" }}>
            <Typography variant="h6" align="center">
            Total Financial Overview
            </Typography>
            <Box
            sx={{
                height: 300, // Set a fixed height for the box to prevent it from growing
                mt: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden", // Prevent chart overflow
            }}
            >
            <Pie data={profitLossData} />
            </Box>
        </Paper>
        </Grid>

        {/* Profit/Loss Summary */}
        <Grid item sx={{ mb: 2 }} xs={12} sm={3} md={3}>
          <Paper sx={{ p: 2, height: "100%" }}>
            <Typography variant="h6">Profit/Loss Summary</Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Total Profit: $500
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              Total Loss: $200
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              Net Profit: $300
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              Profit Percentage: 15%
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              Loss Percentage: 5%
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              Last Month Profit: $150
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              Last Month Loss: $50
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Button variant="contained" color="secondary">
                View Detailed Report
              </Button>
            </Box>
          </Paper>
        </Grid>

        {/* Recent Transactions */}
        <Grid item sx={{ mb: 2 }} xs={12} sm={4} md={4}>
          <Paper sx={{ p: 2, height: "100%" }}>
            <Typography variant="h6">Recent Transactions</Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Transaction 1: Converted 100 USDT to BTC.
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              Transaction 2: Converted 150 USDT to ETH.
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              Transaction 3: Converted 50 USDT to BTC.
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              Transaction 4: Converted 200 USDT to ETH.
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              Transaction 5: Converted 100 USDT to LTC.
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              Transaction 6: Converted 75 USDT to BTC.
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              Transaction 7: Converted 300 USDT to ETH.
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              Transaction 8: Converted 250 USDT to BTC.
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Button variant="contained" color="primary">
                View All Transactions
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardContent;
