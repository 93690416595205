import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../App.css";
import { useDispatch, useSelector } from 'react-redux';

const ForgotPassword = () => {
    const [emailData, setEmailData] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleResetPassword = () => {
        if (emailData === '') {
            setMessage("Please enter your email.");
        } else {
            setMessage("Password reset link sent! Check your inbox.");
            setTimeout(() => {
                navigate('/login'); // Redirect to login page after successful password reset request
            }, 2000);
        }
    };

    return (
        <div style={styles.container}>
            <a 
                href="#"
                onClick={() => navigate('/login')}
                style={styles.backLink}
            >
            <i className="fas fa-arrow-left" style={styles.arrow}></i> 
                Back to Login
            </a>
            <div style={styles.forgotPasswordContainer}>
                <h2 style={{ textAlign: 'center' }}>Forgot Password</h2>
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={emailData}
                    onChange={(e) => setEmailData(e.target.value)}
                    style={styles.input}
                />
                <button onClick={handleResetPassword} className="BUY-BT-2" style={styles.button}>Send Reset Link</button>
                {message && <div style={styles.message}>{message}</div>}
            </div>
        </div>
    );
};

// Inline styles for the component
const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f0f0f0',
        fontFamily: 'Arial, sans-serif',
        position: 'relative', // Needed to position the back link correctly
    },
    backLink: {
        position: 'absolute',
        top: '30px',
        left: '60px',
        color: '#42dc5d',
        fontSize: '14px',
        textDecoration: 'none',
        cursor: 'pointer',
    },
    arrow: {
        marginRight: '8px',
        fontSize: '18px',
    },
    forgotPasswordContainer: {
        background: "white",
        padding: "30px",
        borderRadius: "8px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 10px",
        width: "350px",
        minHeight: "200px",
        paddingLeft: "20px",
        paddingRight: "40px",
    },
    input: {
        width: '100%',
        padding: '10px',
        margin: '20px 0',
        border: '1px solid #ccc',
        borderRadius: '4px',
    },
    button: {
        width: '100%',
        padding: '10px',
        backgroundColor: '#42dc5d',
        color: '#ffffff',
        fontSize: '13px',
        fontWeight: '500',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginTop: '20px',
        transition: 'background-color 0.3s ease',
    },
    message: {
        marginTop: '10px',
        color: 'red',
    },
};

export default ForgotPassword;
