import { createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { Result } from 'ethers';
import { toast } from "react-toastify";

const BASE_URL = "http://34.134.213.246:8008";

const initialState = {
  value: 0,
  isLoading: false,
  allData: []
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    startLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    transData: (state, action) => {
      state.allData = action.payload;
    },
  },
});

export const { increment, startLoading, transData } = authSlice.actions;

export default authSlice.reducer;

// --------------------- Sign up API -----------------------
export function SignupApi({ userRegister, setUserRegister, navigate, dispatch }) {
  return async () => {
    try {
      dispatch(startLoading(true));
      const response = await axios.post(`${BASE_URL}/api/user_register`, userRegister);

      if (response?.status === 200) {
        toast.success("Registered successfully");
        dispatch(startLoading(false));
        navigate("/login");
        setUserRegister({
          username: "",
          firstName: "",
          lastName: "",
          password: "",
          confirmPassword: "",
        });
        return response?.data;
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.email || "Registration failed");
      toast.error(error?.response?.data || "An error occurred");
    } finally {
      dispatch(startLoading(false));
    }
  };
}

// -------------------- Login user ---------------------------
export function LoginUser({ loginData, setLoginData, navigate, dispatch }) {
  return async () => {
    try {
      dispatch(startLoading(true));
      const response = await axios.post(`${BASE_URL}/api/user_login`, loginData);

      if (response?.data?.status === 200) {
        dispatch(startLoading(false));
        // Uncomment if you want to store the token in localStorage
        // localStorage.setItem("token", response?.data?.token);
        // localStorage.setItem("username", loginData?.username);
        navigate("/otp");
        toast.success(response?.data?.message);
        setLoginData({ username: "", password: "" });
        return response?.data;
      } else {
        dispatch(startLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(startLoading(false));
      toast.error(error?.response?.data?.message || "Login failed");
    }
  };
}

// ---------------- Forgot password user -----------------
export function Forgotpassword({
  emailData,
  setEmailData,
  navigate,
  dispatch,
}) {
  return async () => {
    try {
      dispatch(startLoading(true));
      const response = await axios.post(
        `${BASE_URL}/api/password/reset/`,
        emailData
      );

      if (response?.status === 200) {
        dispatch(startLoading(false));
        localStorage.setItem("email", emailData?.email);
        toast.success(response?.data?.message);
        setEmailData({ email: "" });
        navigate("/login");
        return response?.data;
      } else {
        dispatch(startLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.error || "Error resetting password");
      dispatch(startLoading(false));
    }
  };
}

//-------------------fetch-Transactions------------

export function fetchTransactions() {
  return async (dispatch) => {
    const address = localStorage.getItem('walletAddress')
    const apiKey = 'QVSA8F5VTSZ2SHSZH9AXV51A2SXBWBM96I';
    try {
      dispatch(startLoading(true));
      const response = await axios.get(
        `https://api-testnet.bscscan.com/api?module=account&action=txlist&address=${address}&startblock=0&endblock=99999999&page=1&offset=0&sort=desc&apikey=${apiKey}`
      );
     
      if (response?.status === 200) {
        dispatch(startLoading(false));
        if (response?.data?.status == 1) {
          dispatch(transData(response?.data?.result));
        }
        return response?.data;
      }
    } catch (error) {
      console.error(error);
      toast.error(
        error?.response?.data?.message || "Failed to fetch transactions"
      );
    } finally {
      dispatch(startLoading(false));
    }
  };
}
