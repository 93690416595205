import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../App.css";
import { toast } from "react-toastify";

const OtpVerify = () => {
  const [otpValue, setOtpValue] = useState(["", "", "", "", "", ""]);
  const navigate = useNavigate();

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/[^0-9]/.test(value)) return;
    const updatedOtp = [...otpValue];
    updatedOtp[index] = value;
    setOtpValue(updatedOtp);
    if (value !== "" && index < otpValue.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  }

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otpValue[index] === "") {
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
      e.preventDefault();
    }
  };

  const handleSubmit = () => {
    const otpVerify = otpValue.join("");
    if (otpVerify === "123456") {
      toast.success("OTP verification successful");
      navigate('/');
    } else {
      toast.error("Invalid OTP");
    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.otpContainer}>
        <h2 style={{ textAlign: 'center', marginBottom: '25px' }}>Enter OTP</h2>
        <div style={styles.otpInputs}>
          {otpValue.map((digit, index) => (
            <input
              key={index}
              type="text"
              id={`otp-input-${index}`}
              value={digit}
              onChange={(e) => handleOtpChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              maxLength="13"
              style={styles.input}
            />
          ))}
        </div>
        <button onClick={handleSubmit} className="BUY-BT-2" style={styles.button}>Submit</button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f0f0f0',
    fontFamily: 'Arial, sans-serif',
  },
  otpContainer: {
    background: "white",
    padding: "30px",
    borderRadius: "8px",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 10px",
    width: "350px",
    textAlign: "center",
  },
  otpInputs: {
    display: 'flex',
    justifyContent: 'space-between',
    // marginBottom: '10px',
  },
  input: {
    width: '40px',
    height: '40px',
    fontSize: '20px',
    textAlign: 'center',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  button: {
    width: '100%',
    padding: '10px',
    backgroundColor: '#42dc5d',
    color: '#ffffff',
    fontSize: '13px',
    fontWeight: '500',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '40px',
    transition: 'background-color 0.3s ease',
  },
};

export default OtpVerify;
