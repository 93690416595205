import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../App.css";
import { useDispatch, useSelector } from 'react-redux';

const SignUp = () => {
    const dispatch = useDispatch();
    const [userRegister, setUserRegister] = useState({
        username: '',
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
    });

    const [visibilityState, setVisibilityState] = useState({
        showPassword: false,
        showConfirmPassword: false,
    });

    const [errorState, setErrorState] = useState({
        usernameError: '',
        firstnameError: '',
        passwordError: '',
        confirmPasswordError: '',
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserRegister((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrorState((prevErrors) => ({
            ...prevErrors,
            [`${name}Error`]: '', // Reset error for the current field
        }));
    };

    const toggleVisibility = (field) => {
        setVisibilityState((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    const handleSignUp = () => {
        const { username, firstName, password, confirmPassword } = userRegister;
        let isValid = true;
        const newErrors = {
            usernameError: '',
            firstnameError: '',
            passwordError: '',
            confirmPasswordError: '',
        };

        if (!username) {
            newErrors.usernameError = "Username is required.";
            isValid = false;
        }
        if (!firstName) {
            newErrors.firstnameError = "First name is required.";
            isValid = false;
        }
        if (!password) {
            newErrors.passwordError = "Password is required.";
            isValid = false;
        } else if (password !== confirmPassword) {
            newErrors.confirmPasswordError = "Passwords do not match.";
            isValid = false;
        }

        setErrorState(newErrors);

        if (isValid) {
            navigate('/login');
        }
    };

    // dispatch(SignupApi({ userRegister, setUserRegister, navigate, dispatch }))

    return (
        <div style={styles.container}>
            <div style={styles.loginContainer}>
                <h2 style={{ textAlign: 'center' }}>Sign Up</h2>
                <input
                    type="text"
                    name="username"
                    placeholder="Username"
                    value={userRegister.username}
                    onChange={handleChange}
                    style={styles.input}
                />
                {errorState.usernameError && <p style={styles.errorText}>{errorState.usernameError}</p>}
                <input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={userRegister.firstName}
                    onChange={handleChange}
                    style={styles.input}
                />
                {errorState.firstnameError && <p style={styles.errorText}>{errorState.firstnameError}</p>}
                <input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={userRegister.lastName}
                    onChange={handleChange}
                    style={styles.input}
                />
                <div style={styles.passwordContainer}>
                    <input
                        type={visibilityState.showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        value={userRegister.password}
                        onChange={handleChange}
                        style={styles.input}
                    />
                    <i
                        className={`fas ${visibilityState.showPassword ? "fa-eye-slash" : "fa-eye"}`}
                        onClick={() => toggleVisibility('showPassword')}
                        style={styles.icon}
                    ></i>
                </div>
                {errorState.passwordError && <p style={styles.errorText}>{errorState.passwordError}</p>}
                <div style={styles.passwordContainer}>
                    <input
                        type={visibilityState.showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        value={userRegister.confirmPassword}
                        onChange={handleChange}
                        style={styles.input}
                    />
                    <i
                        className={`fas ${visibilityState.showConfirmPassword ? "fa-eye-slash" : "fa-eye"}`}
                        onClick={() => toggleVisibility('showConfirmPassword')}
                        style={styles.icon}
                    ></i>
                </div>
                {errorState.confirmPasswordError && <p style={styles.errorText}>{errorState.confirmPasswordError}</p>}
                <button onClick={handleSignUp} className="BUY-BT-2" style={styles.button}>Sign Up</button>
                <div style={styles.linkContainer}>
                    <button style={styles.linkButton} onClick={() => navigate('/login')}>
                        Already have an account? Login Here
                    </button>
                </div>
            </div>
        </div>
    );
};

// Inline styles for the component
const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f0f0f0',
        fontFamily: 'Arial, sans-serif',
    },
    loginContainer: {
        background: "white",
        padding: "30px",
        borderRadius: "8px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 10px",
        width: "350px",
        minHeight: "400px",
        paddingLeft: "20px",
        paddingRight: "40px",
    },
    input: {
        width: '100%',
        padding: '10px',
        margin: '10px 0 5px',
        border: '1px solid #ccc',
        borderRadius: '4px',
    },
    passwordContainer: {
        position: 'relative',
        width: '100%',
    },
    icon: {
        position: 'absolute',
        top: '57%',
        right: '0px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        color: '#888',
    },
    button: {
        width: '100%',
        padding: '10px',
        backgroundColor: '#42dc5d',
        color: '#ffffff',
        fontSize: '13px',
        fontWeight: '500',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginTop: '20px',
        transition: 'background-color 0.3s ease',
    },
    errorText: {
        color: 'red',
        fontSize: '12px',
        margin: '5px 0',
    },
    linkContainer: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center',
    },
    linkButton: {
        background: 'none',
        color: '#42dc5d',
        fontSize: '14px',
        border: 'none',
        cursor: 'pointer',
        textDecoration: 'none',
    },
};

export default SignUp;
