import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../App.css";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';

const Login = () => {
    const dispatch = useDispatch();
    const [loginData, setLoginData] = useState({
        username: '',
        password: '',
        usernameError: '',
        passwordError: '',
        showPassword: false,
    });
    const navigate = useNavigate();

    const testUsername = "test";
    const testPassword = "test";
    const testAdmin = "admin";
    const testPassAdmin = "admin";

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLoginData((prevState) => ({
            ...prevState,
            [name]: value,
            usernameError: '',
            passwordError: '',
        }));
    };

    const toggleShowPassword = () => {
        setLoginData((prevState) => ({
            ...prevState,
            showPassword: !prevState.showPassword,
        }));
    };

    const handleLogin = () => {
        const { username, password } = loginData;
        let isValid = true;

        if (!username) {
            setLoginData((prevState) => ({
                ...prevState,
                usernameError: "Username is required.",
            }));
            isValid = false;
        }

        if (!password) {
            setLoginData((prevState) => ({
                ...prevState,
                passwordError: "Password is required.",
            }));
            isValid = false;
        }

        if (!isValid) return;

        if (username === testUsername && password === testPassword) {
            navigate('/otp');
            toast.success("An OTP has been sent to your email");
        } else if (username === testAdmin && password === testPassAdmin) {
            navigate('/admin/pages/dashboard');
            toast.success("Admin login successful");
        } else {
            toast.error("Invalid username or password.");
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.loginContainer}>
                <h2 style={{ textAlign: 'center' }}>Login</h2>
                <input
                    type="text"
                    name="username"
                    placeholder="Username"
                    value={loginData.username}
                    onChange={handleChange}
                    style={styles.input}
                />
                {loginData.usernameError && <p style={styles.errorText}>{loginData.usernameError}</p>}

                <div style={styles.passwordContainer}>
                    <input
                        type={loginData.showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        value={loginData.password}
                        onChange={handleChange}
                        style={styles.input}
                    />
                    <i
                        className={`fas ${loginData.showPassword ? "fa-eye-slash" : "fa-eye"}`}
                        onClick={toggleShowPassword}
                        style={styles.icon}
                    ></i>
                </div>
                {loginData.passwordError && <p style={styles.errorText}>{loginData.passwordError}</p>}

                <button onClick={handleLogin} className="BUY-BT-2" style={styles.button}>Login</button>

                <div style={styles.linkContainer}>
                    <button style={styles.linkButton} onClick={() => navigate('/signup')}>
                        Sign Up Here
                    </button>
                    <button style={styles.linkButton} onClick={() => navigate('/forgot-password')}>
                        Forgot Password?
                    </button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f0f0f0',
        fontFamily: 'Arial, sans-serif',
    },
    loginContainer: {
        background: "white",
        padding: "30px",
        borderRadius: "8px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 10px",
        width: "350px",
        minHeight: "300px",
        paddingLeft: "20px",
        paddingRight: "40px",
    },
    input: {
        width: '100%',
        padding: '10px',
        margin: '35px 0px 10px 0px',
        border: '1px solid #ccc',
        borderRadius: '4px',
    },
    passwordContainer: {
        position: 'relative',
        width: '100%',
    },
    icon: {
        position: 'absolute',
        top: '67%',
        right: '0px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        color: '#888',
    },
    button: {
        width: '100%',
        padding: '10px',
        backgroundColor: '#42dc5d',
        color: '#ffffff',
        fontSize: '13px',
        fontWeight: '500',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginTop: '25px',
        transition: 'background-color 0.3s ease',
    },
    errorText: {
        color: 'red',
        fontSize: '12px',
        margin: '5px 0',
    },
    linkContainer: {
        marginTop: '40px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    linkButton: {
        background: 'none',
        color: '#42dc5d',
        fontSize: '14px',
        border: 'none',
        cursor: 'pointer',
        textDecoration: 'none',
    },
};

export default Login;
